<template>
  <div class="relative bg-gray-50 border border-gray-200 rounded h-full overflow-hidden p-8">
    <div v-if="title || viewAllUrl" class="flex items-center justify-between mb-6">
      <div class="flex items-center">
        <span class="text-primary">
          <slot name="icon"></slot>
        </span>
        <h3 v-if="title" class="heading-3">
          {{ title }}
        </h3>
      </div>
      <ActionLink v-if="viewAllUrl" :href="viewAllUrl" class="text-xs flex-none">
        {{ $t("general.view_all") }}
      </ActionLink>
    </div>

    <slot></slot>

    <svg
      v-show="showLoadingIcon"
      class="animate-spin h-5 w-5 text-gray-300 absolute bottom-2 right-2"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
</template>

<script>
import ActionLink from "./links/ActionLink.vue"

export default {
  components: { ActionLink },
  props: {
    title: {
      type: String,
    },
    viewAllUrl: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      showLoadingIcon: this.isLoading,
    }
  },
  watch: {
    isLoading(newVal /*oldVal*/) {
      this.showLoadingIcon = newVal
    },
  },
}
</script>
