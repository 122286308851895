<template>
  <base-card :view-all-url="url" :title="$t('dashboard.spend_report')">
    <fade-in>
      <div v-if="data.length > 0">
        <ul>
          <report-card-item
            v-for="(chart, index) in data"
            :key="chart.name"
            :chart="chart"
            :index="index"
          ></report-card-item>
        </ul>
      </div>
      <div v-else class="flex flex-col items-center justify-center w-full h-full pb-12">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-24 w-24 text-gray-200"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
          />
        </svg>
        <p class="text-center font-semibold">
          {{ $t("dashboard.no_spend_data") }}
        </p>
      </div>
    </fade-in>
  </base-card>
</template>

<script>
import BaseCard from "../../elements/BaseCard.vue"
import ReportCardItem from "./ReportCardItem.vue"
import FadeIn from "../../transitions/FadeIn.vue"
export default {
  components: {
    FadeIn,
    BaseCard,
    ReportCardItem,
  },
  props: {
    url: {
      type: String,
    },
    data: {
      type: Array,
    },
  },
}
</script>
