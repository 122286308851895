<template>
  <li class="group mb-4">
    <div
      v-tooltip.right="{ content: tooltipText }"
      class="bg-primary h-6 rounded-r group-hover:opacity-100 transition-colors ease-in-out duration-300"
      :style="{ width: barWidth, opacity: barOpacity }"
    ></div>
    <div
      class="min-w-min flex items-center justify-between uppercase font-semibold text-2xs"
      :style="{ width: barWidth }"
    >
      <p class="whitespace-nowrap mr-2">{{ chart.name }}</p>
      <p class="whitespace-nowrap">{{ currencyEuro(chart.total) }}</p>
    </div>
  </li>
</template>

<script>
import { currencyEuro, currencyFormat } from "@/helpers/currency-helpers"

export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    chart: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { currencyEuro }
  },
  computed: {
    tooltipText() {
      return currencyFormat(this.chart.percentage) + "%"
    },
    barWidth() {
      return this.chart.percentage + "%"
    },
    barOpacity() {
      return 100 - this.index * 20 + "%"
    },
  },
}
</script>
