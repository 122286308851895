<template>
  <transition
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    :enter-active-class="`transition duration-${duration}`"
    :leave-active-class="`transition duration-${duration}`"
    :mode="mode"
    appear
    @after-enter="$emit('after-enter', $event)"
    @after-leave="$emit('after-leave', $event)"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "out-in",
    },
    duration: {
      type: [String, Number],
      default: 150,
    },
  },
}
</script>
